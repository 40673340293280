
import "./style.scss"
const InputComp = (props) => {
    const { icon, onChange, placeholder, onBlur } = props;

    return (
        <div className="input-style">
            <div><img src={icon} alt="" /></div>
            <input className="ipt" type="text" placeholder={placeholder} onChange={(event) => { onChange && onChange({ value: event.target.value, ...props }) }}
                onBlur={(event) => { onBlur && onBlur({ value: event.target.value, ...props }) }} ></input>
        </div>)
}
export default InputComp; 