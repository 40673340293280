import React from "react";
import { Button, Result } from 'antd';
import "./style.scss"
const Fail = () => {
    return <div className="fail-div">
        <Result
            className="fail-item"
            status="error"
            title="Payment failed"
            subTitle="Please check and modify the following information before resubmitting."
            extra={[
                <Button type="primary" key="console" onClick={() => {
                    window.location.href = "/pay"
                }}>
                    Buy Again
                </Button>,

            ]}
        ></Result></div>
}
export default Fail;