// export const buizCont = () => {
//     API: 'http://study.gosinobus.com/'
// }

export const SETTOKEN = (token) => {
  localStorage.setItem("token", token);
};

export const GETTOKEN = () => {
  return localStorage.getItem("token");
};
export const REMTOKEN = () => {
  localStorage.removeItem("token");
};
