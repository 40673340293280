const getFetchData = (params, url) => {
  let option = { method: params.method };
  if (params.method === "POST") {
    option.body = JSON.stringify(params.body);
  }

  // console.log(params, "params.headers", params.headers);
  option.headers = Object.assign(
    {},
    { "Content-Type": "application/x-www-form-urlencoded" },
    params.headers
  );
  // http://education.xibeicity.com/upload_files/course/20221019/l9fd8kvs_4vvn0dzxdqcj634fb3a39cfe9.png
  return fetch(`https://study.gosinobus.com/index.php/${url}`, option)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.json();
      }
      // 处理错误信息
    })
    .then((res) => {
      if (res.code !== 200) {
        // return { error: true, msg: res.msg };
        return { error: true, msg: "Please operate after login" };
      }
      return res;
    });
};

export default getFetchData;
