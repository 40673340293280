import React, { useState, useContext, useRef } from "react";
import { Progress, notification, message, Select, Carousel } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { picture } from "../../utils/picture";
import { useNavigate } from "react-router-dom";
import API from "../../api";
import "./style.scss";
import { baseUrls } from "../../utils/BaseUrl";
const { Option } = Select;
const ExamQuestion = (props) => {
  const { lessonList = [], questionList = [] } = props;
  const [percent, setPercent] = useState(1);
  const carousel = useRef(null);
  const audioPlayer = useRef();
  const [answerList, setAnswerList] = useState([]);
  const [questionNo, setQuestionNo] = useState(0);
  const [selectValue, setSelectValue] = useState();
  const [audioUrl, setAudioUrl] = useState();

  const showType = (item) => {
    if (item.file_type == "image/png" || item.file_type == "image/jpeg") {
      //展示图片
      // console.log("图片", item.file_type);
      return <img className="type-img" src={`${baseUrls}${item.url}`} alt="" />;
    } else if (item.file_type == "audio/mpeg") {
      // console.log("音频", item.file_type);
      //音频 //获取播放组件
      return (
        <div className="">
          <img
            className="audio-icon"
            src="../assets/audio.png"
            alt=""
            onClick={() => {
              let mp3 = new Audio(`${baseUrls}${item.url}`);
              mp3.play();  //播放
              // mp3.pause();
              //  audioPlayer.current.play(); 
            }}
          />
          {/* <audio
            className="clip"
            ref={audioPlayer}
            id="Q"
            src={`${baseUrls}${item.url}`}
            type="audio/mpeg"
          ></audio> */}
        </div >
      );
    } else if (item.file_type === null) {
      //展示 题目
    }
  };
  //  答案
  const setAnswersFun = (item, index, type) => {
    let answer = Object.keys(JSON.parse(item.answers))[index];
    if (type == "2") {
      answer = [...answerList, answer];
    }
    setAnswerList(answer);
  };
  //
  const carouselOnch = (current) => { };

  const showFlashCards = (item) => {
    return (
      <div className="">
        <p>Audio Speed</p>
        <img className="audio-icon" src="../assets/audio.png" alt="" onClick={() => {
          let mp3 = new Audio(`${baseUrls}${item.pronounce}`);
          mp3.play();  //播放
          // mp3.pause();
          //  audioPlayer.current.play(); 
        }} />
        {/* <audio className="clip" ref={audioPlayer} id="Q" src={`${baseUrls}${item.pronounce}`} type="audio/mpeg"></audio> */}
        {/* <audio id="audio" src={`${baseUrls}${item.pronounce}`} preload="auto" controls loop hidden="true" /> */}
        <div className="word">{item.word}</div>
        <div className="pinyin">{item.pinyin}</div>
        <div>{item.explanation}</div>

      </div>
    );
  };

  const showQestionFun = () => {
    // console.log("questionList", questionList);
    return (
      <Carousel dots={false} autoplay={false} ref={carousel} afterChange={carouselOnch}>
        {questionList.question_list?.map((item, index) => {
          return (
            <div key={index} style={{ height: "150px" }}>
              <p>{item.question_title}</p>
              <div className="content">
                {props.type != "flashcards" ? showType(item) : showFlashCards(item)}
              </div>
              <div className="btn-div">
                {item.answers && Object.values(JSON.parse(item.answers)).map((anItem, answersIndex) => {
                  return (
                    <button className="no-btn btn" key={answersIndex}
                      onClick={() => { setAnswersFun(item, answersIndex, item.question_type) }} >
                      {Object.keys(JSON.parse(item.answers))[answersIndex]} :  {anItem}
                    </button>);
                }
                )}
              </div>
            </div>
          );
        })}
      </Carousel>
    );
  };

  const nextButtonFun = () => {
    if (answerList.length <= 0) {
      return null;
    }
    props.onChange(questionNo, answerList.toString());
    if (questionNo === questionList.question_list?.length - 1) {
      message.success("Please go to the next course");
      return;
    }
    carousel.current?.next();
    setQuestionNo(questionNo + 1);
    setAnswerList([]);
  };
  const flashcardsFun = (state) => {
    props.onChange(questionNo, state);
    if (questionNo === questionList.question_list?.length - 1) {
      message.success("Please go to the next course");
      return;
    }
    carousel.current?.next();
    setQuestionNo(questionNo + 1);
  };

  return (
    <div className="ExamQuestion">
      <div className="examQuestion-hander">
        <div className="back" onClick={() => { props.goBack() }}>
          <LeftOutlined style={{ fontsize: "10px" }} /> Back
        </div>
        <div className="hander-text">
          <Select defaultValue={"lesson - 1"} width={200} listHeight={200} showArrow={false} value={selectValue} style={{ width: "auto" }}
            onChange={(value) => {
              setSelectValue(value);
              props.handleChangeLesson(lessonList[value]);
            }}>
            {lessonList?.map((item, index) => {
              return (<Option key={index} value={index}>{`lesson-${item.sort_order}`}</Option>);
            })}
          </Select>
        </div>
      </div>
      <Progress showInfo={false} percent={((questionNo + 1) / questionList.question_list?.length) * 100} strokeColor="#f39718" trailColor="#d8dcdf" />
      <p>
        {questionNo + 1}/{questionList.question_list?.length}
      </p>
      <div className="carousel-div">
        <div className="carousel-content">{showQestionFun()}</div>
        {props.type !== "flashcards" && (
          <div className="footer-btn">
            <button className="inc-btn">Incorrect</button>
            <button className="next-btn" onClick={nextButtonFun}> Next</button>
          </div>
        )}
        {props.type === "flashcards" && (
          <div className="footer-btn">
            <button className="inc-btn" onClick={() => { flashcardsFun("Forgot") }}>Forgot</button>
            <button className="next-btn" onClick={() => { flashcardsFun("NO Bad") }} > NO Bad </button>
            <button className="next-btn" onClick={() => { flashcardsFun("Easy"); }}> Easy</button>
          </div>
        )}
      </div>

      {/* <p className="progress-bar">{percent}/ <span>{questionList?.length || 0}</span></p> */}
      {/* {showInfo()}
            <ErrMsg {...questionInfo.resData}></ErrMsg> */}
    </div>
  );
};
export default ExamQuestion;
