//测试课程 item组件
import "./style.scss";
import { LeftOutlined } from "@ant-design/icons";
import { baseUrls } from "../../utils/BaseUrl";
const LessonComp = (props) => {
  const { item } = props;
  // console.log(item.icon);
  return (
    <div className="lessonComp">
      <div
        className="class-video-div"
        onClick={() => {
          // //判断是否解锁
          // if (item.is_unlock === 1) {
          //     let params = { "url": item.study_url };
          //     window["filter"] = params;
          //     window.open("/#/VideoPlayer");
          // } else if (item.is_unlock === 0) {
          //     //未解锁
          //     message.error("请观看完上一个视频哦！！！")
          // }
          props.onClick(props.item);
          // setUnitInfo(item);
        }}
      >
        <img src={`${baseUrls}${item.icon}`} alt="" />
      </div>
      <div className="class-info-div">
        <p className="lesson-title">{item.name_chinese}</p>
        <p className="lesson-introduce">{item.introduce_english}</p>
      </div>
      <div className="class-isshow-div">
        {
          item.is_unlock === 1 ? <img className="lock" src="../assets/unlock.png" alt="" /> : <img className="lock" src="../assets/not-unlocked.png" alt="" />
        }
        <img src="" alt="" />
      </div>
    </div>
  );
};
export default LessonComp;
