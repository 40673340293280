import React, { useReducer } from 'react'
import { useRoutes, Routes, Route, nav, Link } from "react-router-dom";
import 'antd/dist/antd.css';
import routes from "./router";
import reducer from './reducer';
import GlobalContext from './context';
import './App.css';
const store = {
  user: null,
  books: null,
  movies: null,
  course: [],
  selectCourse:0, // 默认选择课程
  pageState:'course', // 默认展示课程
  courseUnit: []
};


function App() {
  const element = useRoutes(routes);
  const [state, dispatch] = useReducer(reducer, store);
  const stateData = { state, dispatch };
  return (
    <GlobalContext.Provider value={stateData}>
      <div className="App">
        {element}
        {/* <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Activity />} />   //增加这一行
          <Route path="invoices" element={<Invoices></Invoices>} />
          <Route path="activity" element={<Activity />} />
        </Route>
      </Routes> */}
      </div>
    </GlobalContext.Provider>
  );
}

export default App;
