
import "./style.scss"
import { baseUrls } from "../../utils/BaseUrl"
const UnitComp = (props) => {
    const { icon, onChange, placeholder, onBlur, item = {}, onClick } = props;
    // console.log(item);

    // http://education.xibeicity.com${item.icon}
    return (
        <div className="UnitComp" onClick={() => { onClick(item) }}>
            <div className="util-img-div">
                <img className="img" src={`${baseUrls}${item.icon}`} alt="" />
                {
                    item.is_unlock === 1 ? <img className="lock" src="assets/unlock.png" /> : <img className="lock" src="assets/not-unlocked.png" alt="" />
                }
            </div>
            <div className="util-text">
                <p className="unit-title-text">{item.name_chinese}</p>
                <p className="unit-title-text2">{item.name_english}</p>
            </div>

        </div>
    )
}
export default UnitComp; 