import { Button, Modal } from "antd";
import React, { useRef, useState, useEffect, useContext } from "react";
import API from "../../api";
import { picture } from "../../utils/picture";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { GETTOKEN } from "../../utils/buizCont";
import { useNavigate, Link } from "react-router-dom";
import GlobalContext from "../../context";
import { handerPageChange, handerJumpPage } from "../../utils/apiData";
import DPlayer from "dplayer";
import { baseUrl } from "../../utils/BaseUrl";
const ModalComp = (props) => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(GlobalContext);
  const [open, setOpen] = useState(true);
  const [disabled, setDisabled] = useState(false);
  // const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
  // const draggleRef = useRef<HTMLDivElement>(null);
  const [playData, setPlayData] = useState("");
  const { videoInfo } = props;
  const Hls = require("hls.js");
  const playerVideo = useRef();
  let video = null;
  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setOpen(false);
    handleCancel();
  };

  const handleCancel = () => {
    API.getStudyLogId({
      studyLogId: videoInfo?.studyLogId,
      times: Math.floor(video.video.currentTime),
    }).then((res) => {
      // console.log('tanchaung ---- :', res);
      setOpen(false);
      props.onClick();
      return false;
    });
  };

  useEffect(() => {
    console.log("videoInfo", videoInfo.studyTime);
    video = new DPlayer({
      container: document.getElementById("video"), // 注意：这里一定要写div的dom  // 这里填写.m3u8视频连接
      lang: "zh-cn",
      live: true,
      video: {
        url: `${baseUrl}${videoInfo?.url}&access_token=${GETTOKEN()}`, type: "customHls",
        pic: "",
        live: true,
        customType: {
          customHls: function (video) {
            const hls = new Hls();
            hls.loadSource(video.src);
            hls.attachMedia(video);
          },
        }
      }
    });

    video.play();
    video.seek(videoInfo?.studyTime); // 跳转到特定时间
    //视频结束
    video.on("ended", function (a, b) {
      // 监听函数
      //提交学习进度  const times = Math.floor(video.video.currentTime);
      handleCancel()
    });
  }, [])

  const jumpFlashcards = () => {
    handerPageChange(dispatch, "lesson");
    dispatch({
      type: "sethanderJumpPage",
      jumpPage: "flashcards",
    });
    navigate("/flashcards", { state: { ...videoInfo, chanelMsg: "vedio" } });
  };

  const jumpQuiz = () => {
    handerPageChange(dispatch, "lesson");
    dispatch({
      type: "sethanderJumpPage",
      jumpPage: "quiz",
    });
    navigate("/quiz", { state: { ...videoInfo, chanelMsg: "vedio" } });
  };

  return (
    <>
      <Button onClick={showModal}>Open Draggable Modal</Button>
      <Modal
        title={<div style={{ width: "100%", cursor: "move" }}></div>}
        footer={[
          <Button type="primary" onClick={() => { handleCancel(); jumpFlashcards() }}>Flashcards</Button>,
          <Button type="primary" onClick={() => { handleCancel(); jumpQuiz() }}> Quiz</Button>,
        ]}
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        bodyStyle={{ width: "100%" }}
      >
        <div className="video-player" id='video'>
        </div>
      </Modal>
    </>
  );
};

export default ModalComp;
