import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import API from "../../api";
import CardComp from "../../components/Card";
import GlobalContext from "../../context";
import { LeftOutlined } from "@ant-design/icons";
import ExamQuestion from "../../components/ExamQuestion";
import QuizUnit from "../../components/QuizUnit";
import { Link } from "react-router-dom";
import { Layout, Pagination } from "antd";
import { message } from "antd";

// import "./style.scss";
import "../style.scss";
import {
  setCourseRedux,
  setCourseUnitRedux,
  setSelectCourseIndexRedux,
  setSelectCourseUnitIndexRedux,
  handerPageChange,
} from "../../utils/apiData";
const { Sider, Header, Content } = Layout;

const Flashcards = (props) => {
  const { state, dispatch } = useContext(GlobalContext);
  const {
    course,
    courseUnit,
    selectCourse,
    pageState,
    selectCourseUnitIndex,
    jumpPage,
  } = state;
  const location = useLocation();
  const navigate = useNavigate();
  const [lessonList, setLessonList] = useState([]);
  const [questionList, setQuestionList] = useState([]);
  const [isQuiz, setIsQuiz] = useState("flashcards");
  // 如果没有获取课程 就重新获取
  useEffect(() => {
    if (course?.length <= 0 && jumpPage !== "flashcardsActive") {
      setCourseRedux(dispatch);
    }
  }, []);

  //如果没有获取单元列表
  useEffect(() => {
    if (course?.length > 0) {
      const data = { cId: course[selectCourse]?.cId, page: 1, limit: 100 };
      course[selectCourse]?.cId && setCourseUnitRedux(dispatch, data);
    }
  }, [course, selectCourse]);

  useEffect(() => {
    if (selectCourseUnitIndex !== undefined) {
      API.getCourseLessonApi(courseUnit[selectCourseUnitIndex].unitId).then(
        (data) => {
          setLessonList(data);
          if (jumpPage === "flashcards") {
            dispatch({ type: "sethanderJumpPage", jumpPage: "" });
            getExamQuestion(location?.state?.lessonId);
          } else {
            getExamQuestion(data[0].lessonId);
          }
        }
      );
    }
  }, [selectCourseUnitIndex]);


  useEffect(() => {
    if (jumpPage === "flashcardsActive") {

      getQuizExperience(location?.state?.lessonId);
    }
  }, [jumpPage]);

  const getQuizExperience = (lessonId) => {
    API.experienceWordCard(lessonId).then((resData) => {
      if (resData.error) {
        message.error(resData.msg);
        // message.error("Please operate after purchase");
      }
      setQuestionList(resData.data);
    })
  }

  // 获取测试题哇
  const getExamQuestion = (lessonId) => {
    API.examWord(course[selectCourse].cId, lessonId).then((resData) => {
      if (resData.error) {
        message.error(resData.msg);
        // message.error("Please operate after purchase");
      }
      setQuestionList(resData.data);
    });
  };

  const handerLessonChange = (indexCurrent, answers) => {

    const data = {
      usId: questionList.usId,
      examId: questionList.question_list[indexCurrent].examId,
      studyTime: 11,
      answer: answers,
    };
    if (jumpPage === "flashcardsActive") {
      // console.log(questionList.question_list[indexCurrent], 'indexCurrent, answers', indexCurrent, answers);
      API.submitTest(data).then((resData) => {
        if (resData.msg == "success") {
          // message.success("提交成功");
        }
      })
    } else {
      API.submitExamAnswer(data).then((resData) => {
        if (resData.msg == "success") {
          // message.success("提交成功");
        }
      });
    }

  };
  const handerTestLessonAnswer = () => {
  }
  const onChangePage = () => {
    // console.log('ddddddddddddddGO');
    switch (pageState) {
      case "course":
        setSelectCourseIndexRedux(dispatch, 0);
        navigate("/home");
        break;
      case "unit":
        handerPageChange(dispatch, "course");
        break;
    }
  };
  return (
    <Layout className="Quiz">
      <Header className="home-header">
        <div className="home-nav">
          <Link to="/quiz" className="nav-quiz"><div><img src="../assets/quiz.png" /></div>Quiz</Link>
          <Link to="/flashcards" className="nav-flashcards"><div><img src="../assets/flashcards.png" /></div> Flashcards</Link>
        </div>
      </Header>
      {pageState !== "lesson" && (
        <Header className="content-header">
          <div className="header-ret" onClick={onChangePage}><LeftOutlined style={{ fontsize: "18px" }}></LeftOutlined></div>
          <div className="header-title">Flashcards</div>
        </Header>
      )}
      <Layout>
        <Content>
          {pageState === "course" && (
            <div className="course-list">
              {course && course.map((item, indexID) => {
                if (indexID % 4 === 0) {
                  const listData = course.slice(indexID, indexID + 4);
                  return (
                    <div key={indexID} className="unit-list-row">
                      {listData.map((item, index) => {
                        return (
                          <div key={index} className="course-list-comp">
                            <CardComp item={item} onClick={() => {
                              const inD = indexID + index;
                              console.log(inD);
                              setSelectCourseIndexRedux(dispatch, inD); handerPageChange(dispatch, "unit")
                            }} ></CardComp>
                          </div>
                        )
                      })}
                    </div>)
                }
              })}
            </div>
          )}
          {pageState === "unit" && <div className="course-list">
            {courseUnit && courseUnit.map((item, indexID) => {
              if (indexID % 4 === 0) {
                const list = courseUnit.slice(indexID, indexID + 4);
                return (
                  <div key={indexID} className="unit-list-row">
                    {list.map((item, index) => {
                      return (
                        <div key={index} className="unit-list-comp">
                          <QuizUnit isQuiz={"flashcards"} item={item} onClick={(item) => {
                            const inD = indexID + index;
                            console.log(inD)
                            setSelectCourseUnitIndexRedux(dispatch, index); handerPageChange(dispatch, "lesson");
                          }} />
                        </div>
                      );
                    })}
                  </div>
                )
              }
            })}
          </div>}
          {pageState === "lesson" && (
            <div className="course-list">
              <ExamQuestion {...{ lessonList, questionList, type: 'flashcards' }} onChange={handerLessonChange}
                goBack={() => {
                  if (jumpPage === "flashcardsActive") {
                    navigate("/pay");
                    dispatch({ type: "sethanderJumpPage", jumpPage: "" });
                    return;
                  } else {
                    setSelectCourseUnitIndexRedux(dispatch, undefined); handerPageChange(dispatch, "unit")
                  }

                }}
                handleChangeLesson={(item) => {
                  getExamQuestion(item.lessonId)
                }}></ExamQuestion>
            </div>
          )}
        </Content>
      </Layout>
    </Layout>
  );
};
export default Flashcards;
