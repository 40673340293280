// import { JSEncrypt } from './jsencrypt/bin/jsencrypt.js'
import { JSEncrypt } from "jsencrypt"
export const encryption = (data) => {
    //非对称加密使用JSEncrypt  以下是非对称加密的公钥
    //-----BEGIN PUBLIC KEY-----
    // MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQComntJOgPwmwV/4BFD6XuRnV3c
    // axNh77COpGCQDVrDKj/imY8iuwtVfv2D+c0nF0qMZvqDSHNaToyf2RfqEqB6Jzkw
    // vHaK0PiYaJPzvJWbvFNiYXF7UX/0T6i+TnH3zYzKkXutk8QOmIPobqkwxsSiQxSP
    // sxxNKBaQICj8kjb7wwIDAQAB
    //-----END PUBLIC KEY-----



    //以下是加密示例
    //  let encrypt = new JSEncrypt();
    //  encrypt.setPublicKey(PUBLICKEY);
    //  let encryptChar =encrypt.encrypt("加密字符串")
    //  加密后须encodeURI 处理后传参（以下是处理逻辑）
    //  encodeURI(encryptChar).replace(/\+/g, '%2B')
    //
    //
    //
    let PUBLICKEY = `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQComntJOgPwmwV/4BFD6XuRnV3c
    axNh77COpGCQDVrDKj/imY8iuwtVfv2D+c0nF0qMZvqDSHNaToyf2RfqEqB6Jzkw
    vHaK0PiYaJPzvJWbvFNiYXF7UX/0T6i+TnH3zYzKkXutk8QOmIPobqkwxsSiQxSP
    sxxNKBaQICj8kjb7wwIDAQAB`;
    let encrypt = new JSEncrypt();
    encrypt.setPublicKey(PUBLICKEY);
    let encryptChar = encrypt.encrypt(data);
    let datas = encodeURI(encryptChar).replace(/\+/g, '%2B');
    // console.log("datas", datas)
    return datas;

}