import React from "react";
import { useEffect, useState, useContext } from "react";
import "./style.scss";
import PurchaseCourses from "../PurchaseCourses";
// import videojs from "video.js";
import API from "../../api";
import { UserOutlined } from "@ant-design/icons";
import { baseUrl } from "../../utils/BaseUrl";
import { GETTOKEN } from "../../utils/buizCont";
import DPlayer from "dplayer";
import GlobalContext from "../../context";
import { useNavigate, useLocation } from "react-router-dom";
import {
  setCourseRedux,
  setCourseUnitRedux,
  setSelectCourseIndexRedux,
  setSelectCourseUnitIndexRedux,
  handerPageChange,
} from "../../utils/apiData";
import { message } from "antd";
const Home = () => {
  const Hls = require("hls.js");
  const [isShow, setIsShow] = useState(false);
  const [isTopNUmber, setIsTopNUmber] = useState(0);
  const [isLeftNUmber, setIsLeftNUmber] = useState(25);
  const [titleList, setTitleList] = useState([]);
  //课程介绍
  const [classContent, setClassContent] = useState({});
  //课包列表
  const [purchaseCoursesList, setPurchaseCoursesList] = useState([]);
  //试听课
  const [experienceVideo, setExperienceVideo] = useState();
  const { state, dispatch } = useContext(GlobalContext);
  const navigate = useNavigate();
  const addClassName = (index) => {
    const liArr = document.querySelectorAll(".nav-ul li");
    setIsTopNUmber(index * 120);
    liArr.forEach((element, index1) => {
      element.className = "list-item";
      if (index == index1) {
        element.className = "list-item bag-item";
      }
    });
    setClassContent(titleList[index]);
    // console.log(titleList[index].cId);
    getClassBag(titleList[index].cId);
    getExperienceVideoUrl(titleList[index].cId);
  };

  // //监听页面离开
  // window.onbeforeunload = function (e) {
  //     var e = window.event || e;
  //     e.returnValue = ("确定离开当前页面吗？");
  // }
  //获取课包列表
  const getClassBag = (id) => {
    API.getCourseBagListApi(id, { page: 1, limit: 10 }).then((res) => {
      // console.log(res);
      setPurchaseCoursesList("");
      setPurchaseCoursesList(res);
      //
      dispatch({ type: "setCourse", course: res });
    });
  };
  //获取试听课url  这里返回的 studyLogId 再提交视频进度的地方用到
  const getExperienceVideoUrl = (id) => {
    // console.log(experienceVideo);
    API.experienceVideo(id).then((res) => {
      // console.log("111111111111111111", res);
      if (res.code === 200) {
        setExperienceVideo(res);
        const url = `${baseUrl}${res.data.studyUrl
          }&access_token=${GETTOKEN()}`;
        // console.log(url);
        let video = new DPlayer({
          container: document.getElementById("video"), // 注意：这里一定要写div的dom
          lang: "zh-cn",
          live: true,
          video: {
            url: url, // 这里填写.m3u8视频连接
            type: "customHls",
            pic: "",
            customType: {
              customHls: function (video) {
                const hls = new Hls();
                hls.loadSource(video.src);
                hls.attachMedia(video);
              },
            },
          },
        });
        video.play(); // 播放
        //视频结束
        video.on("ended", function (a, b) {
          // 监听函数
          // console.log(a, b);
          //提交学习进度
          const times = Math.floor(video.video.currentTime);
          // console.log(times);
          subCourseProgress(res.data.studyLogId, times);
        });
        video.on("abort", function () {
          console.log("暂停");
        });
      }
    });
  };
  //视频观看进度提交
  const subCourseProgress = async (studyLogId, time) => {
    const res = await API.experienceCourseProgress({
      studyLogId: studyLogId,
      times: time,
    });
    console.log("视频观看进度提交", res);
  };
  //获取测试题
  // const getExperienceQuiz = async (id) => {
  //   const res = await API.experienceQuiz(id);
  //   console.log("测试题", res);
  //   //返回的data有数据 就跳转
  //   if (res.code === 200) {
  //     // 跳转
  //     // window.location.href = "/#/ceshi"
  //     let params = { data: res };
  //     // window["filter"] = params;
  //     // window.open("/#/ceshi");
  //   } else {
  //     message.error(res.msg);
  //   }
  // };
  //获取词卡
  // const getFlashCard = async (id) => {
  //   const res = await API.experienceWordCard(id);
  //   console.log("词卡", res);
  //   //返回的data有数据 就跳转
  //   if (res.code === 200) {
  //     // 跳转
  //     // let params = { "data": res };
  //     // window["filter"] = params;
  //     // window.open("/#/ceshi");
  //   } else {
  //     message.error(res.msg);
  //   }
  // };

  // const flashcardBtn = () => {
  //   getFlashCard(classContent.cId);
  // };
  // const quizBtn = () => {
  //   getExperienceQuiz(classContent.cId);
  // };
  useEffect(() => {
    //课程列表
    API.getClassListApi().then((res) => {
      // console.log(res);
      setTitleList(res);
      // console.log(res[0].cId);
      getExperienceVideoUrl(res[0].cId);
      getClassBag(res[0].cId);
      setClassContent(res[0]);
    });
    // var player = videojs('my-player');
    // player.play();
    // 获取播放进度
    // var whereYouAt = player.currentTime();
  }, []);
  const handerChangeCourse = (index) => {
    addClassName(index);
    setSelectCourseIndexRedux(dispatch, index);
    setSelectCourseIndexRedux(dispatch, index);
  }
  const handerQuiz = () => {
    handerPageChange(dispatch, "lesson");
    dispatch({ type: "sethanderJumpPage", jumpPage: "quizActive" });
    navigate("/quiz", { state: { lessonId: classContent.cId } });
  }

  const handerFlashcards = () => {
    handerPageChange(dispatch, "lesson");
    dispatch({ type: "sethanderJumpPage", jumpPage: "flashcardsActive" });
    navigate("/flashcards", { state: { lessonId: classContent.cId } });
  }
  return (
    <div className="home">
      <div className="to-learn">
        <div><img src="../assets/sino-bus.png" alt="" /></div>
        <p onClick={() => { navigate("/home") }}>To learn </p>
        <div onClick={() => {
          // 判断是否登录
          if (GETTOKEN()) {
            message.success("Logged in");
          } else {
            navigate("/login");
          }

        }}><UserOutlined style={{ fontSize: 20 }} /></div>

      </div>
      <div className="home-div">
        <div className="nav-left">
          <div className="left-content">
            <ul className="nav-ul">
              {titleList.map((item, index) => {
                return (
                  <li key={index} className={["list-item ", index == 0 ? "bag-item" : null].join("")}
                    onClick={() => { handerChangeCourse(index) }}>
                    {item.name_english}

                  </li>
                )
              })}
            </ul>
          </div>
        </div>
        <div className="middle-div">
          <img className="left-img" style={{ top: isTopNUmber }} src="../../../src/assets/images/left.png" alt="" />
          <img className="bottom-img" style={{ top: isLeftNUmber }} src="../../../src/assets/images/bottom.png" alt="" />
        </div>
        <div className="home-right">
          <div className="home-right-content">
            <div id="video" className="video"></div>
            <div className="ceshi">
              <button className="flashcards-btn" onClick={() => { handerFlashcards() }}> Flashcards </button>
              <button className="quiz-btn" onClick={() => { handerQuiz() }}>Quiz </button>
            </div>
            <p>{classContent.content_english}</p>
          </div>
        </div>

      </div>
      <div className="payment-method">

        <PurchaseCourses purchaseCoursesList={purchaseCoursesList} setPurchaseCoursesList={setPurchaseCoursesList} />
      </div>
      <div className="class-img-pc">
        <img className="pc-class" src="../assets/jieshao.jpg" alt="" />
      </div>
      <div className="class-img">
        <img className="pc-class" src="../assets/jieshao-yd.jpg" alt="" />
      </div>
    </div>
  );
};
export default Home;
