import { useEffect, useState, createContext, useContext } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Layout, Pagination, Menu } from "antd";
import { UserOutlined, ShoppingCartOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom";
import UnitComp from "../../components/Unit";
import LessonHeard from "../../components/LessonHeard";
import Quiz from "../Quiz";
import CardComp from "../../components/Card";
import LessonComp from "../../components/Lesson";
import ModalComp from "../../components/Modal";
import Flashcards from "../Flashcards";
import MyInfo from "../../components/MyInfo";
import API from "../../api";
import "../style.scss";
// const { Header, Content, Sider } = Layout;

import {
  setCourseRedux,
  setCourseUnitRedux,
  setSelectCourseIndexRedux,
  setSelectCourseUnitIndexRedux,
  handerPageChange,
} from "../../utils/apiData";
import GlobalContext from "../../context";
import "./style.scss";
import { GETTOKEN } from "../../utils/buizCont";
const { Sider, Header, Content } = Layout;

const Home = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(GlobalContext);
  const { course, courseUnit, selectCourse, pageState, selectCourseUnitIndex, index } =
    state;
  const [lessonList, setLessonList] = useState([]);
  const [videoInfo, setVideoInfo] = useState({ show: false });
  const [openCour, setOpenCour] = useState(true);
  const [myState, setMyState] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [chat, setChat] = useState(true);
  // 初始化课程
  useEffect(() => {
    //获取个人信息
    API.userInfo().then((res) => {
      // console.log(res);
      setUserInfo(res)
    });
    setCourseRedux(dispatch);
  }, []);
  // 获取单元列表
  useEffect(() => {
    if (course?.length > 0) {
      const data = { cId: course[selectCourse].cId, page: 1, limit: 100 };
      setCourseUnitRedux(dispatch, data);
    }
  }, [course, selectCourse]);

  // 获取选中单元的lesson
  useEffect(() => {
    if (selectCourseUnitIndex !== undefined) {
      API.getCourseLessonApi(courseUnit[selectCourseUnitIndex].unitId).then(
        (data) => {
          setLessonList(data);
        }
      );
    }
  }, [selectCourseUnitIndex]);

  // console.log("selectCourse", selectCourse);

  const onChangeMyState = () => {
    //判断是否登录
    setMyState(!myState);
    if (GETTOKEN()) {

    } else {
      if (myState == false)
        navigate("/login");
    }
  };

  return (
    <Layout className="home">

      <Layout>
        <Header className="home-header">
          <div className="home-nav">
            <div
              to="/quiz"
              className="nav-quiz"
              onClick={() => {
                setSelectCourseIndexRedux(dispatch, 0);
                handerPageChange(dispatch, "course");
                navigate("/quiz");
              }}
            >
              <div>
                <img src="../assets/quiz.png" />
              </div>{" "}
              Quiz

              <div
                to="/flashcards"
                className="nav-flashcards"
                onClick={() => {
                  setSelectCourseIndexRedux(dispatch, 0);
                  handerPageChange(dispatch, "course");
                  navigate("/flashcards");
                }}
              >
                <div>
                  <img src="../assets/flashcards.png" />
                </div>
                Flashcards
              </div>
            </div>{" "}

            <div className="Purchasecourse" onClick={() => { navigate("/pay") }}>   <ShoppingCartOutlined size={20} /></div>
          </div>

        </Header>
        <Header className="content-header">
          <div
            className="header-ret"
            onClick={() => {
              setOpenCour(!openCour);
            }}
          >
            <img src="../assets/courese.png" alt="" /><span>Courese</span>
          </div>
          <div className="header-title">
            <img src="../assets/ask.png" />{" "}
            <span>{course[selectCourse]?.name_english}</span>
          </div>
          <div onClick={onChangeMyState}><UserOutlined /></div>
        </Header>
        <Layout>
          {openCour && (
            <Sider
              width={200}
              id="sider-layout-background"
              style={{ background: "#fff" }}
            >
              {course && (
                <Menu
                  style={{ width: "100%" }}
                  selectedKeys={[selectCourse?.toString()]}
                  defaultOpenKeys={[selectCourse]}
                  mode="inline"
                  onClick={(value) => {
                    handerPageChange(dispatch, "course");
                    setSelectCourseUnitIndexRedux(dispatch, undefined);
                    setSelectCourseIndexRedux(dispatch, Number(value.key));

                  }}
                >
                  {course.map((item, index) => {
                    return (
                      <Menu.Item key={index} value={index}>
                        {item.name_english}
                      </Menu.Item>
                    );
                  })}
                </Menu>
              )}
            </Sider>
          )}
          <Layout>
            <Content className="content-div" style={{ background: "#e4f7fe " }}>
              {pageState === "course" && (
                <div className="unit-list">
                  {courseUnit &&
                    courseUnit.map((item, indexID) => {
                      if (indexID % 3 === 0) {
                        const listData = courseUnit.slice(indexID, indexID + 3);

                        return (
                          <div className="unit-div" key={indexID}>
                            {listData.map((item, index) => {
                              return (
                                <div key={index}>
                                  <UnitComp
                                    item={item}
                                    onClick={(item) => {
                                      // alert(item)
                                      handerPageChange(dispatch, "lesson");
                                      const inD = indexID + index;
                                      console.log('pppppppppppppppppppppp', indexID, index);
                                      console.log(inD, "dianji ", indexID);
                                      setSelectCourseUnitIndexRedux(
                                        dispatch,
                                        inD
                                      );
                                    }}
                                  ></UnitComp>
                                </div>
                              );
                            })}
                          </div>
                        );
                      }
                    })}
                </div>
              )}
              {pageState === "lesson" && (
                <div className="lesson-list" style={{ "overflow-y": "scroll", height: "100%" }}>
                  <LessonHeard lessonList={courseUnit[selectCourseUnitIndex]} />
                  {lessonList &&
                    lessonList.map((item, index) => {
                      return (
                        <LessonComp
                          key={index}
                          item={item}
                          onClick={() => {
                            API.study(
                              course[selectCourse]?.cId,
                              item.lessonId
                            ).then((resData) => {
                              console.log("=====================", resData);
                              let params = {
                                url: resData?.data?.studyUrl,
                                studyLogId: resData.data.studyLogId,
                                show: true,
                                lessonId: item.lessonId,
                                studyTime: resData.data.studyTime,
                              };
                              setVideoInfo({ ...videoInfo, ...params });
                            });
                          }}
                        />
                      );
                    })}
                </div>
              )}
            </Content>
          </Layout>
        </Layout>
      </Layout>
      {myState && (
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          width={400}
          style={{ overflow: "auto" }}
        >
          <MyInfo userInfo={userInfo} onClose={onChangeMyState}></MyInfo>
        </Sider>
      )}
      {videoInfo.show && (
        <div style={{ width: "50%" }}>
          <ModalComp
            videoInfo={videoInfo}
            onClick={() => {
              setVideoInfo({ show: false });
            }}
          />
        </div>
      )}
    </Layout>
  );
};
export default Home;
