import React from "react";
import { Button, Result } from 'antd';
import "./style.scss"
import { useEffect } from "react";
const Success = () => {
    useEffect(() => {
        localStorage.getItem("order");
        console.log(localStorage.getItem("order"));
    }, []);
    return <div className="success-div">
        <Result
            className="succ-item"
            status="success"
            title="SPayment succeeded"
            subTitle={`Order number: ${localStorage.getItem("order")} Cloud server configuration takes 1-5 minutes, please wait.`}
            extra={[
                <Button size="large" type="primary" key="console" onClick={() => {
                    window.location.href = "/login"
                }}>
                    Please log in first to learn
                </Button>,
            ]}
        />
    </div>
}
export default Success;