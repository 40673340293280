import getFetchData from "../utils/fetchRequest";
import { GETTOKEN } from "../utils/buizCont";
const API = {
    // -------------课时http://education.xibeicity.com/course/get-course-lesson?unitId=5&access_token=quuvmv0t8vq3j2b4isd9ib6gpf
    getEmailCode(data) {
        return getFetchData({ method: 'POST', body: data }, `login/send-email-code?access_token=`);
    },
    register(data) {
        return getFetchData({ method: 'POST', body: data }, `login/register?access_token=${GETTOKEN()}`);
    },
    getCountryCode() {
        return getFetchData({ method: 'GET' }, `/site/get-area-code?access_token=`).then((res) => res)
    },
    login(data) {
        return getFetchData({ method: 'POST', body: data }, `login`).then((res) => res)
    },
    getCourseListApi() {
        return getFetchData({ method: 'get' }, `course/get-course-list?access_token=${GETTOKEN()}`).then((res) => res)
    },
    getCourseUnitListApi(cId, page, limit) {
        return getFetchData({ method: 'get' }, `course/get-course-unit?cId=${cId}&page=${page}&limit=${limit}&access_token=${GETTOKEN()}`).then((res) => res)
    },
    getCourseLessonApi(id) {

        return getFetchData({ method: 'get' }, `course/get-course-lesson?unitId=${id}&access_token=${GETTOKEN()}`).then((res) => res.data)
    },
    //测试 题
    examQuestion(cId, lessonId) {

        return getFetchData({ method: 'GET' }, `/study/exam-question?cId=${cId}&lessonId=${lessonId}&access_token=${GETTOKEN()}`).then((res) => {
            // if (res.code === 100) {
            //     // throw new Error('fffffffff');
            //     return { error: res.msg }
            // }
            return res;

        })
    },
    getClassListApi() {
        return getFetchData({ method: 'get' }, `course/get-course-list?access_token=${GETTOKEN()}`).then((res) => res.data)
    },
    // //体验视频接口 get  http://education.xibeicity.com/experience?cId=1
    // experienceVideo(cId) {
    //     return getFetchData({ method: 'get' }, `/experience?cId=${cId}&access_token=${getToken()}`).then((res) => res)
    // },
    // //体验课体验课时学习进度提交接口  POST http://education.xibeicity.com/experience/update-study-log
    // experienceCourseProgress(data) {
    //     return getFetchData({ method: 'POST', body: data }, `/experience/update-study-log?access_token=${GETTOKEN()}`).then((res) => res)
    // },
    // //体验词卡题  http://education.xibeicity.com/experience/exam-word?cId=1
    // experienceWordCard(cId) {
    //     return getFetchData({ method: 'get' }, `/experience/exam-word?cId=${cId}&access_token=${getToken()}`).then((res) => res)
    // },
    // //体验测试题 http://education.xibeicity.com/experience/exam-question?cId=1 
    // experienceQuiz(cId) {
    //     return getFetchData({ method: 'GET' }, `/experience/exam-question?cId=${cId}&access_token=${getToken()}`).then((res) => res)
    // },

    // //体验测试提交答案  http://education.xibeicity.com/experience/submit-exam-answer

    // submitTest(cId) {
    // submitTest(cId) {
    //     return getFetchData({ method: 'POST' }, `/experience/exam-question?cId=${cId}&access_token=${getToken()}`).then((res) => res)

    // },
    experienceVideo(cId) {
        return getFetchData({ method: 'get' }, `/experience?cId=${cId}&access_token=${GETTOKEN()}`).then((res) => res)
    },
    getCourseBagListApi(id, data) {
        // get("/course/get-course-package?cId=" + id + data);page=1&limit=10&cId=12
        return getFetchData({ method: 'get' }, `/course/get-course-package?cId=${id}&page=${data.page}&limit=${data.limit}&access_token=${GETTOKEN()}`).then((res) => res.data)
    },
    //体验课体验课时学习进度提交接口  POST http://education.xibeicity.com/experience/update-study-log
    experienceCourseProgress(data) {
        return getFetchData({ method: 'POST', body: data }, `/experience/update-study-log?access_token=${GETTOKEN()}`).then((res) => res)
    },
    //体验词卡题  http://education.xibeicity.com/experience/exam-word?cId=1
    experienceWordCard(cId) {
        return getFetchData({ method: 'get' }, `/experience/exam-word?cId=${cId}&access_token=${GETTOKEN()}`).then((res) => res)

    },
    //体验测试题 http://education.xibeicity.com/experience/exam-question?cId=1 
    experienceQuiz(cId) {
        return getFetchData({ method: 'GET' }, `/experience/exam-question?cId=${cId}&access_token=${GETTOKEN()}`).then((res) => res)
    },

    //体验测试提交答案  http://education.xibeicity.com/experience/submit-exam-answer

    submitTest(data) {
        return getFetchData({ method: 'POST', body: data }, `/experience/submit-exam-answer?access_token=${GETTOKEN()}`).then((res) => res)

    },
    //  http://education.xibeicity.com/study?cId=5&lessonId=6
    //  
    study(cId, lessonId) {
        return getFetchData({ method: 'GET' }, `/study?cId=${cId}&lessonId=${lessonId}&access_token=${GETTOKEN()}`).then((res) => res)
    },


    // getClassHourListApi(id) {
    //     console.log('===getClassHourListApi===========', id);
    //     return getFetchData({ method: 'get' }, `/course/get-course-lesson?unitId=${id}&access_token=${getToken()}`).then((res) => res.data)
    // },
    // getCourseUnitListApi(cId, page, lim) {
    //     // return (id, data) => get("/course/get-course-unit?cId=" + id, data); &page=${data.page}&limit=${data.limit}
    //     console.log('----------------ddddd');
    //     return getFetchData({ method: 'get' }, `/course/get-course-unit?cId=${cId}&page=${page}&limit=${lim}&access_token=${getToken()}`).then((res) => res)
    // },
    // // 订单 http://education.xibeicity.com/course/get-course-lesson?unitId=5


    // //学习
    // //   http://education.xibeicity.com/study?cId=5&lessonId=6
    // getEntStudy(cId, lessonId) {
    //     return getFetchData({ method: 'get' }, `/study?cId=${cId}&lessonId=${lessonId}&access_token=${getToken()}`).then((res) => res.data)
    // },
    // // http://education.xibeicity.com/study/update-study-log?studyLogId=1&times=300
    getStudyLogId(data) { // 待定 TODO 
        return getFetchData({ method: 'POST', body: data }, `/study/update-study-log?access_token=${GETTOKEN()}`).then((res) => res.data)
    },
    // // http://education.xibeicity.com/study/exam-question?cId=1&lessonId=2
    // examQuestion(cId, lessonId) {
    //     console.log('getToken()', getToken());
    //     return getFetchData({ method: 'GET' }, `/study/exam-question?cId=${cId}&lessonId=${lessonId}&access_token=${getToken()}`).then((res) => {
    //         if (res.code === 100) {
    //             // throw new Error('fffffffff');
    //             return { error: res.msg }
    //         }
    //         return res.data;

    //     })
    // },
    // // http://education.xibeicity.com/study/exam-word?cId=1&lessonId=2
    examWord(cId, lessonId) {
        return getFetchData({ method: 'GET' }, `/study/exam-word?cId=${cId}&lessonId=${lessonId}&access_token=${GETTOKEN()}`).then((res) => res)
    },
    // //  http://education.xibeicity.com/study/submit-exam-answer
    submitExamAnswer(data) {
        return getFetchData({ method: 'POST', body: data }, `study/submit-exam-answer?access_token=${GETTOKEN()}`).then((res) => res)
    },
    // //注册
    // //获取国家码接口  http://education.xibeicity.com/site/get-area-code
    // getCountryCode() {
    //     return getFetchData({ method: 'GET' }, `/site/get-area-code?access_token=${getToken()}`).then((res) => res)
    // },
    //判断手机号是否正确  http://education.xibeicity.com/site/check-phone?phone=+8613333333333&regionCode=CN
    judgePhone(phone, code) {
        return getFetchData({ method: 'GET' }, `/site/check-phone?phone=${phone}&regionCode=${code}&access_token=${GETTOKEN()}`)
    },
    // //用户中心
    userInfo() {
        return getFetchData({ method: 'GET' }, `/user/get-user-info?access_token=${GETTOKEN()}`).then((res) => res.data)
    },
    // //修改密码 http://education.xibeicity.com/user/modify-password 
    changePassword(data) {
        return getFetchData({ method: 'POST', body: data }, `/user/modify-password?access_token=${GETTOKEN()}`).then((res) => res)
    },
    // //订单列表 http://education.xibeicity.com/order/get-order-list?page=1&limit=10
    // orderList() {
    //     return getFetchData({ method: 'GET' }, `/order/get-order-list?page=${1}&limit=${10}&access_token=${getToken()}`).then((res) => res.data)
    // },
    // //提交订单 ttp://education.xibeicity.com/order/create-order
    placeOrder(data) {
        return getFetchData({ method: 'POST', body: data }, `order/create-order?access_token=${GETTOKEN()}`).then((res) => res)
    },
    //订单支付  http://education.xibeicity.com/order/pay-order?orderSn=202209292019953706&payType=alipay
    orderPayment(orderSn, payType) {
        return getFetchData({ method: 'GET' }, `/order/pay-order?orderSn=${orderSn}&payType=${payType}?access_token=${GETTOKEN()}`).then((res) => res)

    },

}

export default API;