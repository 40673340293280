import React from "react";
import { Button, Checkbox, Form, Input, message } from 'antd';
import API from "../../api";
import { encryption } from "../../utils/encryption"
const ChangePassworld = ({ isModalOpen, setIsModalOpen }) => {

    //     old_password	
    // 是	String
    //     旧密码
    //     password	
    // 是	String
    //     新密码
    //     confirm_password
    const onFinish = async (values) => {
        console.log('Success:', values);
        let arr = [];
        Object.values(values).map((item) => {
            arr.push(item);
        });
        console.log(arr);
        const res = await API.changePassword({ old_password: encryption(arr[0]), "password": encryption(arr[1]), "confirm_password": encryption(arr[2]) });
        console.log(res);
        if (res.error === true) {
            // message.error(res.msg);
        } else {
            message.success(res.msg)
            setIsModalOpen(false);
        }

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    return (
        <Form
            name="basic"
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                label="Old Passworld"
                name="old_password"
                rules={[{ required: true, message: 'Please input your old passworld!' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="New Password"
                name="password"
                rules={[{ required: true, message: 'Please input your password!' }]}
            >
                <Input.Password />
            </Form.Item>
            <Form.Item
                label="Confirm Password"
                name="confirm_password"
                rules={[{ required: true, message: 'Please input your password!' }]}
            >
                <Input.Password />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 10, span: 16 }}>
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
            </Form.Item>

        </Form>

    )

}
export default ChangePassworld;