function reducer(state, action) {
  switch (action.type) {
    case "setCourse":
      return { ...state, course: action.course };
    case "setCoursUnit":
      return { ...state, courseUnit: action.courseUnit };
    case "setPageState":
      return { ...state, pageState: action.pageState };
    case "setSelectCourse":
      return { ...state, selectCourse: action.selectCourse };

    case "setSelectCourseUnitIndex":
      return { ...state, selectCourseUnitIndex: action.selectCourseUnitIndex };

    case "sethanderJumpPage":
      return { ...state, jumpPage: action.jumpPage };

    case 'myInfo':
    return {...state,myInfo:action.myInfo}
    case "setBooks":
      return { ...state, books: action.books };
    case "setMovies":
      return { ...state, movies: action.movies };
    default:
      throw new Error();
  }
}

export default reducer;
