import { useState, useCallback, useEffect } from "react";
import API from '../../api';
import { encryption } from '../../utils/encryption'
import NLS from './string_nls';
import "./style.scss"
import { message, Select, Spin } from "antd";
import InputComp from '../../components/InputComp';
import { useNavigate } from 'react-router-dom';
import { SETTOKEN, GETTOKEN } from "../../utils/buizCont";
import { LoadingOutlined } from '@ant-design/icons';
import { getOverflowOptions } from "antd/es/_util/placements";
const { Option } = Select;
const Login = () => {
    const navigate = useNavigate();
    const [countryCodeList, setCountryCodeList] = useState([]);
    const [loginCom, setLoginCom] = useState(false)
    const [registerToken, setRegisterToken] = useState('');
    const [registerData, setRegisterDate] = useState({});
    const [liginData, setLiginData] = useState({});
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    // 初始化 列表数据
    useEffect(() => {
        //如果已经登录 可以直接跳转home 
        // if (GETTOKEN()) {
        //     message.success("Logged in");
        //     navigate("/home");
        // }
        API.getCountryCode().then((resData) => {
            // console.log(resData);
            setCountryCodeList(resData.data)
        })
    }, [])
    // 准备 注册数据
    const setRegisterInputValue = (data) => {
        const obj = { [data.id]: data.value }
        // console.log("obj-----------", { ...registerData, ...obj });
        setRegisterDate({ ...registerData, ...obj })
    }
    //密码正则  密码由大写字母+小写字母+数字，8-16位组成
    let passWorldReg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d]{8,16}$/;
    //手机号正则
    let phoneReg = /^([+]?0?\d{2,3}-?|\([+]?0?\d{2,3}\)|\([+]?0?\d{2,3}\))?\d+$|^([+]?0?\d{2,3}-?|\([+]?0?\d{2,3}\)|\([+]?0?\d{2,3}\))?[1-9]\d{4,10}(-\d{1,10})?$/;

    // 点击注册button
    const registerButton = useCallback(async () => {
        // console.log("registerData", registerData);
        //国家码不能为空
        if (registerData.area_code) {
            var arr = registerData.area_code?.split("/");
            // console.log(arr); // ['+86', 'CN']0 
            const res = await API.judgePhone(arr[0] + registerData.phone, arr[1]);
            // console.log(res);
            const code = encodeURI(arr[0]).replace(/\+/g, '%2B')
            // console.log(code);
            const parms = {
                area_code: code,
                phone: encryption(registerData.phone),
                email: encryption(registerData.email),
                emailCode: registerData.emailCode,
                password: encryption(registerData.password),
                confirm_password: encryption(registerData.password),
                registerToken: registerToken,
            }
            // console.log("parms", parms);
            //验证手机号和国家码是否匹配
            if (res.data.isValid === true) {
                //判断邮箱 和验证码
                if (registerData.email) {
                    //判断密码
                    if (passWorldReg.test(registerData.password)) {
                        //判断两次密码是否相同
                        // if (registerData.password === registerData.confirm_password) {
                        //3. 开始注册
                        message.loading('Action in progress..', 1);
                        setTimeout(() => {
                            API.register(parms).then((resData) => {
                                // console.log(resData);
                                if (resData.error) {
                                    message.error(resData.msg);
                                } else {
                                    message.success("login was successful");
                                    setLoginCom(false);
                                }
                            })
                        }, 1500);
                        // } else {
                        //     message.error("The passwords are different");
                        // }
                    } else {
                        //密码验证不通过
                        message.error("The password consists of upper case letters+lower case letters+numbers, 8-16 characters！");
                    }
                } else {
                    //邮箱或者邮箱验证码错误
                    message.error("Email or email verification code error");
                }
            } else {
                //手机号验证不通过
                message.error("Incorrect mobile phone number or country code");
            }
        } else {
            message.error("Incorrect mobile phone number or country code");
        }

    }, [registerData])

    const loginButton = useCallback(() => {
        alert(localStorage.getItem("email"));
        if (localStorage.getItem("email")) {

        }
        setLoginCom(false);
    })
    const quickRgistration = () => {
        setLoginCom(true);
    }
    // 获取 email code
    const getEmailCodeFun = useCallback((data) => {

        data.value && API.getEmailCode({ "email": data.value }).then((resData) => {
            // console.log("EmailresData", resData);
            if (resData.error) {
                message.error(resData.msg);
            } else {
                setRegisterToken(resData.data.registerToken)
                message.success("The verification code is being sent...")
            }
        })

    })

    // 准备登录数据
    const setSignInputValue = (data) => {
        const obj = { [data.id]: data.value }
        setLiginData({ ...liginData, ...obj })
    }
    const signButton = () => {
        const parms = {
            ...liginData, areaCode: registerData.area_code || '+86',
            account: encryption(liginData.account),
            password: encryption(liginData.password)
        };
        API.login(parms).then((resData) => {
            message.loading("Loading", 1);
            setTimeout(() => {
                if (resData.error) {
                    message.error(resData.msg);
                } else {
                    SETTOKEN(resData?.data?.access_token);

                    navigate('/pay', { state: { accessToken: resData?.data?.access_token }, replace: true });
                    // navigate(-1);
                }
            }, 1500);

        })
    }

    const signIn = (emailName) => {
        return (
            <div>
                <div className="sign-info">
                    <div><span>*</span> {NLS.telephone_num} </div>
                    <InputComp icon='assets/email.png' placeholder="email" id='account' onChange={setSignInputValue} ></InputComp>
                </div>
                <div className="sign-info">
                    <div><span>*</span>{NLS.login_password}</div>
                    <InputComp icon='assets/pass.png' placeholder="password" id='password' onChange={setSignInputValue}></InputComp>
                </div>
                <div className="login-button" onClick={signButton}>{NLS.sign_in} </div>
                <div className="login-account">{NLS.other_account}<span onClick={quickRgistration}>{NLS.quick_registration}</span></div>
            </div>)
    }

    const register = () => {
        return (
            <div>
                <div className="input-style">
                    <div><img src="assets/phone.png" alt="" /></div>
                    <Select bordered={false} defaultValue="Area code" style={{ width: 120 }} onChange={(value) => { console.log(value); setRegisterInputValue({ value: value, id: 'area_code' }) }}>
                        {countryCodeList.map((item, index) => {
                            return <Option dropdownMatchSelectWidth={true} key={index} value={item.num + "/" + item.code}>{item.num}/{item.english_name}</Option>
                        })}
                    </Select>
                    <input type="text" placeholder="Phone Number" onChange={(event) => { setRegisterInputValue({ value: event.target.value, id: 'phone' }) }} />
                </div>
                <InputComp icon='assets/email.png' placeholder="Email" id='email' onChange={setRegisterInputValue} onBlur={getEmailCodeFun}></InputComp>
                <InputComp icon='assets/email-code.png' placeholder="Email verification code" id='emailCode' onChange={setRegisterInputValue}></InputComp>
                <InputComp icon='assets/pass.png' placeholder="Password(Upper case,lower case+number (8-16))" id='password' onChange={setRegisterInputValue}></InputComp>
                {/* <InputComp icon='assets/pass.png' placeholder="Comfirm Passworld" id='confirm_password' onChange={setRegisterInputValue}></InputComp> */}
                <div className="login-button" onClick={registerButton}>{NLS.sign_in} </div>
                <div className="login-tips">{NLS.register_tips} </div>
            </div>
        )
    }

    return (<div className="login">
        <div className="login-text" >{NLS.sign_up}</div>

        <div className="login-content">
            {loginCom ? register() : signIn()}
        </div>
        {loginCom && <div className="login-footer" onClick={loginButton}>
            <div>Existing account number?</div>
            <div className="login-button-tips">Click here to<span> [login]</span></div>
        </div>}

    </div>);
}
export default Login; 