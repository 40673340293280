import React from "react";
import "./style.scss";
import { baseUrls } from "../../utils/BaseUrl";
const QuizUnitComp = (props) => {
  const { isQuiz, item = {}, onClick } = props;
  // console.log("isQuiz````````````````", isQuiz);
  // console.log(item);
  return (
    <div
      className="quiz-unit-item"
      key={""}
      onClick={() => { onClick(item) }}
    >
      <div className="head-portrait">
        <img
          id="head-portrait-img"
          src={`${baseUrls}${item.icon}`}
          alt=""
        />
      </div>

      <div className="app-content">
        <p className="title-text">{item.name_english}</p>
        {isQuiz == "flashcards" ? <p className="total-number">
          <span>{item.my_word_num}</span> / {item.word_num}
        </p> : <p className="total-number">
          <span>{item.my_question_num}</span> / {item.question_num}
        </p>
        }

      </div>
    </div>
  );
};
export default QuizUnitComp;
