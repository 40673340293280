//测试课程 item组件
import "./style.scss"
const CardComp = (props) => {
    const { icon, onChange, placeholder, onBlur, item = {}, onClick } = props;
    const colorArr = ["#ffcd7a", "#dc9fed", "#ee8896", "#7ad1fe", "#cceca3", "#88ede5"];
    // console.log("item", item);
    return (
        <div className="cardComp" onClick={() => { onClick && onClick(item) }}>
            <div className="orange-div" >
                <div className="units-title">{item.name_english}</div>
                <div className="units-content" style={{ "background": "#ffcd7a" }}>
                    <p className="orange-text1-p">• {item.unit_num} Units</p>
                    <p className="orange-text2-p">• {item.name_chinese}</p>
                    <p className="orange-text3-p">  Cards Mastered</p>
                </div>
            </div>
        </div>
    )
}
export default CardComp; 