import "./style.scss";
import React from "react";
import { Layout, Progress, Popover, Modal, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useState, useContext } from "react";
import API from "../../api";
import GlobalContext from "../../context";
import { useEffect } from "react";
import { REMTOKEN } from "../../utils/buizCont";
import ChangePassworld from "../ChangePassworld/inde";
import { GETTOKEN } from "../../utils/buizCont";
import { useNavigate } from "react-router-dom";
const { Sider } = Layout;
const MyInfo = (props) => {
  const { state, dispatch } = useContext(GlobalContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const { userInfo } = props;
  const { myInfo } = state;
  const navigate = useNavigate();
  const content = (
    <div>
      <p className="sign-out" onClick={() => {
        REMTOKEN();
        message.success("sign-out");

      }}>sign out</p>
      <hr />
      <p className="change-pass" onClick={() => {
        setIsModalOpen(!isModalOpen)
      }}>Change Password</p>
    </div>
  );
  // console.log(userInfo);
  useEffect(() => {
    // console.log("----获取用户信息");
    //获取用户详情
  }, [state.myInfo]);
  return (
    <div className="myuser">
      {/* 修改密码 */}
      <Modal title="Change Password" open={isModalOpen} centered
        onCancel={() => setIsModalOpen(false)}
        keyboard={true}
        destroyOnClose={true}
        footer={false}
      >
        <ChangePassworld isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      </Modal>
      <Sider className="sider-w" width={400}>
        <div className="users-div">
          <div className="off-users">
            <CloseOutlined
              style={{
                fontSize: "20px",
                color: "red",
              }}
              onClick={(event) => {
                props.onClose();
                console.log("阻止时间冒泡");
                //显示设置内容  先判断是否登录
                event.preventDefault();
              }}
            />
          </div>
          <div className="head">
            <div className="head-portrait-div">
              <img
                className="head-portrait-icon"
                src="../assets/user.png"
                alt=""
              />
            </div>
            <div className="userData-div">
              <p className="username">{userInfo?.username || "sino - bus"}</p>
              <div
                onClick={() => {
                  //出现退出和修改密码
                  setOpen(!open)
                }}
              >
                <img
                  className="setting-icon"
                  src="../assets/06.png"
                  alt=""
                />{" "}
                {/* <span className="set-text-span">Settings</span> */}
                <Popover placement="bottom" content={content} trigger="click" open={open}>
                  <span>Settings</span>
                </Popover>
              </div>
              <div className="level-div">
                Sinobus Level: <span>{userInfo?.level || "1"}</span>
              </div>
            </div>
          </div>
          <div className="days-div">
            <img
              className="daya-icon"
              src="../assets/07.png"
              alt=""
            />{" "}
            <span className="color-org">{userInfo?.day || "0"}</span>{" "}
            <span className="days-text">days</span>
            <img
              className="check-icon"
              src="../assets/08.png"
              alt=""
            />
          </div>
          <div className="complete-div">
            <span className="text2-p">Incomplete</span>
            <span className="text1-p">Current Streak</span>
          </div>
        </div>
        <div className="target-div">
          <h2 className="target-text-h2">DAILY GOALS</h2>
          <div className="progress-div">
            <div className="Lesson-div">
              <span>Complete 1 Lesson</span>
              <span className="span-right">0/1</span>
              <Progress percent={10} showInfo={false} trailColor="#787878" />
            </div>
            <div className="medal-div">
              <span>Earn 1 Gold Medel</span>
              <span className="span-right">0/1</span>
              <Progress percent={10} showInfo={false} trailColor="#787878" />
            </div>
          </div>
        </div>
        <div className="stats-div">
          <h2 className="stats-text-h2">STUDY STATS</h2>
          <div className="days-goals-div">
            <div className="days1">
              <span className="span1">1</span>{" "}
              <img
                id="best-streak-icon"
                src="../assets/09.png"
                alt=""
              />
              <p>Best Streak</p>
            </div>
            <div className="days1">
              <span className="span1">2</span>{" "}
              <img
                id="lessons-icon"
                src="../assets/08.png"
                alt=""
              />
              <p>Lessons Completed</p>
            </div>
            <div className="days1">
              <span className="span3">3</span>{" "}
              <img
                id="gold-icon"
                src="../assets/10.png"
                alt=""
              />
              <p>Gold Medal Earned</p>
            </div>
          </div>
        </div>
      </Sider>{" "}
    </div>
  );
};
export default MyInfo;
