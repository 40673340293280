import React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./style.scss"
import API from "../../api";
import { GETTOKEN } from "../../utils/buizCont";
import { baseUrl } from "../../utils/BaseUrl";
import { useState } from "react";
const Order = () => {
    const location = useLocation();
    const [orderInfo, setOrderInfo] = useState();
    const [email, setEmail] = useState();
    useEffect(() => {
        console.log(location);
        // console.log(location?.state?.orderInfo);
        setOrderInfo(location?.state?.orderInfo);
        if (GETTOKEN()) {
            API.userInfo().then((res) => {
                console.log(res.email);
                setEmail(res.email)
            });
            let ipt = document.querySelector(".email-div");
            console.log(ipt);
            ipt.style.display = "none";
        } else {

        }
    }, []);
    const payClick = (id) => {
        let reg = /^[A-Za-z\d]+([-_\.][A-Za-z\d]+)*@([A-Za-z\d]+[-\.])+[A-Za-z\d]{2,4}(,[A-Za-z\d]+([-_\.][A-Za-z\d]+)*@([A-Za-z\d]+[-\.])+[A-Za-z\d]{2,4})*$/;

        // navigate("/order");
        //判断是否登录  
        //1.登录 ：获取用户的邮箱 
        //2.没登录 用户自己输入邮箱

        if (reg.test(email)) {
            API.placeOrder({ "packageId": id, "email": email }).then((res) => {
                console.log("订单", res);
                if (res.code === 200) {
                    //跳转支付页面 传参数
                    console.log(res.data.order_sn);
                    //存一下订单号
                    localStorage.setItem("order", res.data.order_sn)
                    // window.location.href = `${baseUrls}/order/pay-order?orderSn=${res.data.order_sn}&payType=${"stripe"}&access_token=${GETTOKEN()}`;
                    window.open(`${baseUrl}/order/pay-order?orderSn=${res.data.order_sn}&payType=${"stripe"}&access_token=${GETTOKEN()}`);
                }
            })
        } else {
            let ipt = document.querySelector(".input-style");
            console.log(ipt);
            ipt.style.border = "2px solid red";
        }
    }
    return <div className="order">
        <div className="order-content">
            <p className="order-title">{orderInfo?.course_name}</p>
            <div className="order-info">
                <div className="method-div">
                    <p>Payment method：Stripe</p>
                    <hr />
                    <p><span className="right">√</span> WeChat </p>
                    <hr />
                    <p><span className="right">√</span> Bank card</p>
                    <hr />
                    <p><span className="right">√</span> Alipay  </p>
                    <hr />
                </div>

                <div className="price">
                    <div className="price-text">Price</div>
                    <div className="order-price"> ${orderInfo?.current_price}</div>
                    <div></div>
                </div>

                <p className="time">Course validity：<span>{orderInfo?.cycle}</span> day</p>
                <div className="email-div">
                    <div className="input-style">
                        <div><img src="assets/email.png" alt="" /></div>
                        <input className="ipt-email" type="text" placeholder="E-mail" onChange={(e) => {
                            setEmail(e.target.value);
                            let ipt = document.querySelector(".input-style");
                            ipt.style.border = "2px solid #dbdbdb";
                        }} />

                    </div>
                    <div className="pass-text">Used to receive login password</div>
                </div>

                <button className="reg" onClick={() => { payClick(orderInfo?.id) }}>Pay and register</button>
            </div>


        </div>
    </div>
}
export default Order;