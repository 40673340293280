import { RouteObject } from "react-router-dom";
import Login from "../pages/Login";
import Home from "../pages/Home";
import Quiz from "../pages/Quiz";
import Flashcards from "../pages/Flashcards";
import VideoPlayer from "../pages/VideoPlayer";
import Success from "../pages/Success";
import Fail from "../pages/Fail";
import Pay from "../pages/Pay"
import Order from "../pages/Order";
const routes = [{
    exact: true,
    path: '/',
    element: <Pay />,
}, {
    exact: true,
    path: 'index.html',
    element: <Pay />
},
{
    exact: true,
    path: 'home',
    element: <Home />
}, {
    exact: true,
    path: 'quiz',
    element: <Quiz />
}, {
    exact: true,
    path: 'flashcards',
    element: <Flashcards />,

}, {
    exact: true,
    path: '/pay',
    element: <Pay />,
}, {
    exact: true,
    path: '/login',
    element: <Login />,
}, {
    exact: true,
    path: '/success.html',
    element: <Success />,
}, {
    exact: true,
    path: '/cancel.html',
    element: <Fail />,
}, {
    exact: true,
    path: '/Order',
    element: <Order />,
}
]

export default routes;