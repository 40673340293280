import React, { useState, useContext } from "react";
import "./style.scss";
import { Button, Modal } from 'antd';
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import API from "../../api";
import { GETTOKEN } from "../../utils/buizCont";
import { baseUrl } from "../../utils/BaseUrl";
import Login from "../Login";
import GlobalContext from "../../context";
const PurchaseCourses = ({ purchaseCoursesList, setPurchaseCoursesList }) => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(true);
  const { state, dispatch } = useContext(GlobalContext);
  const payClick = (item) => {

    navigate("/order", { state: { orderInfo: item } });
    // API.placeOrder({ "packageId": id }).then((res) => {
    //   if (res.code === 200) {
    //     //跳转支付页面 传参数
    //     console.log(res.data.order_sn);
    //     // window.location.href = `${baseUrls}/order/pay-order?orderSn=${res.data.order_sn}&payType=${"stripe"}&access_token=${GETTOKEN()}`;
    //     window.open(`${baseUrl}/order/pay-order?orderSn=${res.data.order_sn}&payType=${"stripe"}&access_token=${GETTOKEN()}`);
    //   }
    // })
  }

  const compSingle = (item, index) => {
    return (
      <div>
        <li className="courses-single-pc" key={index}>
          <button className="time-btn">{Math.trunc(item.cycle / 30)} MONTH</button>
          <div className="price"><span className="symbol">$</span>{((item.current_price / Math.trunc(item.cycle / 30))).toFixed(2)}</div>
          <p className="per-text">per month</p>
          <p className="total-price">Billed $ {item.current_price} every {Math.trunc(item.cycle / 30)} month</p>
          <button className="start-btn" onClick={() => {
            //提交订单
            payClick(item)
          }} >Start Learning Now! >>></button>
        </li>
        <li className="courses-single" key={index} onClick={() => {
          payClick(item)
        }}>
          <button className="time-btn">{Math.trunc(item.cycle / 30)} MONTH</button>
          <div className="single-right">
            <div className="price"><span className="symbol">$</span>{((item.current_price / Math.trunc(item.cycle / 30))).toFixed(2)}<span className="price-month">/month</span></div>
            <p className="total-price">Billed $ {item.current_price}every {Math.trunc(item.cycle / 30)} month</p>
          </div>
        </li>
      </div>
    );
  };
  return (
    <div className="purchase-courses">
      <div className="header-text">
        <p> Choose Your Plan</p>
      </div>
      <ul className="payment-method-ul">
        {purchaseCoursesList.map((item, index) => {
          // console.log(item);
          return compSingle(item, index);
        })}
      </ul>

    </div>
  );
};
export default PurchaseCourses;
