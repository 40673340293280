/*
 * @Author: baoer
 * @Date:   2022-10-26 00:33:14
 * @Last Modified by:   baoer
 * @Last Modified time: 2022-10-26 15:03:28
 */
import { useEffect, useState, createContext, useContext } from "react";
import API from "../api";

// 获取所有的课程
export const setCourseRedux = (dispatch) => {
  API.getCourseListApi().then((resData) => {
    dispatch({ type: "setCourse", course: resData.data });
  });
};
// 获取选中课程的单元
export const setCourseUnitRedux = (dispatch, item) => {
  const { cId, page = 1, limit = 100 } = item;
  API.getCourseUnitListApi(cId, page, limit).then((resData) => {
    dispatch({ type: "setCoursUnit", courseUnit: resData.data });
  });
};

export const setSelectCourseIndexRedux = (dispatch, selectCourseIndex) => {
  // console.log("ddd", selectCourseIndex);
  dispatch({ type: "setSelectCourse", selectCourse: selectCourseIndex });
};

// 展示那一部分
export const handerPageChange = (dispatch, pageName) => {
  // console.log("展示那一部分", pageName);
  dispatch({ type: "setPageState", pageState: pageName });
};

//选中的是哪一个课程下面的单元
export const setSelectCourseUnitIndexRedux = (
  dispatch,
  selectCourseUnitIndex
) => {
  // console.log("setSelectCourseUnitIndexRedux", selectCourseUnitIndex);
  dispatch({
    type: "setSelectCourseUnitIndex",
    selectCourseUnitIndex: selectCourseUnitIndex,
  });
};

export const handerJumpPage = (dispatch, pageName) => {
  dispatch({
    type: "sethanderJumpPage",
    selectCourseUnitIndex: pageName,
  });
};
