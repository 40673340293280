import React from "react";
import "./style.scss";
import { baseUrls } from "../../utils/BaseUrl";
const lessonHeard = (props) => {
  const { lessonList, lessonTitle } = props;
  // console.log("lessonList.icon", lessonList, lessonTitle);
  return (
    <div className="lesson-info-div">
      <div className="img-div">
        <img
          src={`${baseUrls}${lessonList.icon} `}
          alt=""
        />
      </div>
      <div className="text-div">
        <div id="classname-div">
          <span className="class-name">{lessonList.name_chinese}</span>    {lessonList.name_english}
        </div>
        <div className="unit-name">{lessonList.introduce_english}</div>
        {/* <div>Getting Started</div> */}
      </div>
    </div>
  );
};
export default lessonHeard;
